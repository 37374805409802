import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VParallax } from 'vuetify/lib/components/VParallax';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "about" },
    [
      _c(
        VCard,
        { staticClass: "ma-3 pa-3" },
        [
          _c(
            VRow,
            { staticClass: "ma-0 pa-0" },
            [
              _c(
                VCol,
                { staticClass: "ma-0 pa-0", attrs: { sm: "12", md: "12" } },
                [
                  _c(
                    VParallax,
                    {
                      staticStyle: { height: "130px" },
                      attrs: {
                        dark: "",
                        src: require("@/assets/background-azul-escuro-reduzido-2.jpg")
                      }
                    },
                    [
                      _c(
                        VRow,
                        { attrs: { align: "center", justify: "center" } },
                        [
                          _c(
                            VCol,
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "h1",
                                {
                                  staticClass: "display-1 font-weight-thin mb-4"
                                },
                                [_vm._v(" Fluxo de Protesto ")]
                              ),
                              _c("h3", { staticClass: "heading" }, [
                                _vm._v("Recuperi | Abril | 2023")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "mt-3", staticStyle: { height: "650px" } }, [
            _c(
              "object",
              {
                staticClass: "pdf-container",
                attrs: { data: _vm.linkToPdf, type: "application/pdf" }
              },
              [_c("iframe", { attrs: { src: "" + _vm.linkToPdf } })]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }